<template>
  <b-row style="height: 100%">
    <b-col style="padding-right: 0;">
      <div id="map-container" style="width: 100%; height: 100%" v-if="ready">
        <MglMap
          container="map-container"
          :accessToken="accessToken"
          :mapStyle="mapStyle"
          :center="center"
          :zoom="zoom"
          @load="onMapLoaded"
        >
          <MglMarker
            v-for="(item, index) in coordinatesMarker"
            :key="'MakerId_' + index + cont"
            :coordinates="coordinatesMarker[index]"
            color="#00D455"
          >
            <MglPopup>
              <div class="demo-inline-spacing">
                <b-avatar
                  variant="light-dark"
                  style="margin-right: 0.5rem; margin-top: 0rem"
                >
                  <feather-icon
                    :icon="possible_icons[devices[index].icon].icon"
                    size="20"
                  />
                </b-avatar>
                <div style="margin-top: 0rem">
                  <h5 class="mb-0">
                    {{ devices[index].name }}
                  </h5>
                  <small class="text-muted">
                    {{ new Date(item[2] * 1000).toLocaleString() }}
                  </small>
                </div>
              </div>
            </MglPopup>
          </MglMarker>
          <MglMarker
            color="#00D455"
            v-for="(item, index) in last_positions_coords"
            :key="
              'MakerId_' + index + last_positions_coords.length + position_cont
            "
            :coordinates="last_positions_coords[index]"
          >
            <MglPopup>
              <div class="demo-inline-spacing">
                <div style="margin-top: 0rem">
                  <h6 class="mb-0">
                    {{ item[2] }}
                  </h6>
                </div>
              </div>
            </MglPopup>
          </MglMarker>
          <MglNavigationControl position="top-right" />
        </MglMap>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BAvatar, BCard, BRow, BCol, BFormRadio, BButton } from "bootstrap-vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl, MglPopup } from "v-mapbox";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import EventBus from "@/store/eventBus";
import api from "../../service/api.js";

export default {
  props: ["request", "start_date", "final_date", "mode"],
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    MglMap,
    MglMarker,
    BButton,
    MglNavigationControl,
    MglPopup,
  },

  data() {
    return {
      position_cont: 0,
      last_positions_coords: [],
      fences_points: [],
      heat_data: {
        type: "FeatureCollection",
        crs: {
          type: "name",
        },
        features: [],
      },
      destroy_it: false,
      refresh_data: [],
      cont: 0,
      coordinates: [],
      ready: false,
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      accessToken:
        "pk.eyJ1IjoiZHRpYWdvIiwiYSI6ImNrYzB4b2JlODE4azMyc3U2dnJ0M3RzdmIifQ.JjLKI9mfpEot4J2FNEtNOQ",
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [-46.9397181, -19.5933794],
      coordinatesMarker: [],
      coordinatesToGo: [0, 0],
      zoom: 4,
      devices: [],
    };
  },
  async created() {
    console.log("Entrou no created do mapa! ")
    var self = this;
    this.mapbox = Mapbox;

    // Se receber sinal, chamar função
    EventBus.$on("change_device", (data) => {
      self.onChangeDevice(data);
      self.cont += 1;
    });

    EventBus.$on("updateLastPositions", (data) => {
      self.last_positions_coords = [];
      data.forEach((element, index) => {
        self.last_positions_coords[index] = [
          Number(element.value.split(",", 2)[1]),
          Number(element.value.split(",", 2)[0]),
          element.valueTime,
        ];
      });
      self.position_cont += 1;
    });

    EventBus.$on("heat_map", (device, start_date, final_date) => {
      this.plotHeatMap(device.id, start_date, final_date);
    });

    EventBus.$on("delete_heat_map", () => {
      var mapLayer = this.map.getLayer("earthquakes-heat");
      if (typeof mapLayer !== "undefined") {
        this.map.removeLayer("earthquakes-heat").removeSource("earthquakes");
      }
    });

    EventBus.$on("delete_fences", () => {
      this.fences_points.forEach((element, index) => {
        var mapLayer = this.map.getLayer("trace_" + index);
        if (typeof mapLayer != "undefined") {
          this.map.removeLayer("trace_" + index).removeSource("trace_" + index);
        }
      });
      EventBus.$emit("stop_fences_loading");
    });

    // Exibir cercas
    EventBus.$on("create_fences", (points) => {
      points.forEach((element, index) => {
        let data = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: "",
              },
            },
          ],
        };
        data.features[0].geometry.coordinates = [element[0]];
        this.map.addSource("trace_" + index, { type: "geojson", data: data });

        this.map.addLayer({
          id: "trace_" + index,
          type: "line",
          source: "trace_" + index,
          paint: {
            "line-color": "yellow",
            "line-opacity": 0.9,
            "line-width": 3,
          },
        });

        this.map.jumpTo({ center: element[0], zoom: 14 });
        this.map.setPitch(20);
        let i = 0;
        for (let i = 0; i < element.length; i++) {
          data.features[0].geometry.coordinates.push(element[i]);
          this.map.getSource("trace_" + index).setData(data);
          this.map.panTo(element[i]);
        }
      });
      this.fences_points = points;
      EventBus.$emit("stop_fences_loading");
    });

    // Pegar posições iniciais
    if (this.request != "") {
      this.devices = await api.find_devices(this.request);

      for (let i = 1; i <= this.devices.length; i++) {
        try {
          let response = await api.get_lasts_coords(
            this.request.split("&id=", 100)[i]
          );
          if (response == "error") {
            EventBus.$emit(
              "alert",
              "danger",
              "Algo deu errado",
              "Tente mais tarde ou contate os administradores do sistema"
            );
          } else {
            self.coordinatesMarker[i - 1] = [
              response[0].value.split(",")[1],
              response[0].value.split(",")[0],
              response[0].valueTime,
            ];
            EventBus.$emit("patch_valueTime", response[0]);
          }
        } catch {
          self.devices.splice(i - 1, 1);
        }
      }
    }
    this.ready = true;
    EventBus.$emit("close_devices_overlay");
  },
  destroyed() {
    this.destroy_it = true;
  },
  methods: {
    // Dados em tempo real
    async request_data() {
      let self = this;

      if (self.destroy_it) {
        clearInterval(self.refresh_data);
      }

      for (let i = 1; i <= self.devices.length; i++) {
        let response = await api.get_lasts_coords(
          self.request.split("&id=", 70)[i]
        );
        if (response != "error") {
          EventBus.$emit("patch_valueTime", response[0]);
          self.coordinatesMarker[i - 1] = [
            response[0].value.split(",")[1],
            response[0].value.split(",")[0],
            response[0].valueTime,
          ];
        }
      }
      self.cont += 1;
    },
    async onMapLoaded(event) {
      this.refresh_data = setInterval(this.request_data, 10000);

      this.map = event.map;
      this.$store.map = event.map;
      this.map.resize();
      this.asyncActions = event.component.actions;
    },

    // Voar até dispositivo escolhido
    async onChangeDevice(device) {
      let response = await api.get_lasts_coords(device);
      this.coordinatesToGo = [
        response[0].value.split(",")[1],
        response[0].value.split(",")[0],
      ];
      this.cont += 1;
      EventBus.$emit("device_datas", response);
      this.newParams = await this.asyncActions.flyTo({
        center: this.coordinatesToGo,
        zoom: 17,
        speed: 1,
      });
    },
    async plotHeatMap(device, start_date, final_date) {
      let request_variables = "&variableId=7";
      let request_device = "&deviceId=" + device;
      start_date = new Date(start_date).toISOString();
      final_date = new Date(final_date).toISOString();
      let request_invertal =
        "&start_time=" + start_date + "&end_time=" + final_date;
      let response = await api.find_data(
        request_device,
        request_variables,
        request_invertal
      );

      try {
        if (
          response.error == "DATAS NÃO ENCONTRADAS" ||
          response.error == "Não há datas cadastradas no periodo desejado!"
        ) {
          EventBus.$emit(
            "alert",
            "danger",
            "Dados não encontrados",
            "Não existe dados no período especificado para este dispositivo"
          );
          return;
        }
      } catch {}

      let filtered_response = response.datas.filter((e) => e.valueTime > 1); // Retirando todas as mensagens com valueTime igual zero, ou seja mensagens erradas

      filtered_response.forEach((item, index) => {
        try {
          this.heat_data.features.push({
            type: "Feature",
            properties: {
              // id: "ak16994521",
              mag: 1,
              time: 1507425650893,
              felt: null,
              tsunami: 0,
            },
            geometry: {
              type: "Point",
              coordinates: [
                item.value.split(",")[1],
                item.value.split(",")[0],
                0,
              ],
            },
          });
        } catch {}
      });

      this.map.addSource("earthquakes", {
        type: "geojson",
        data: this.heat_data,
      });

      this.map.addLayer(
        {
          id: "earthquakes-heat",
          type: "heatmap",
          source: "earthquakes",
          maxzoom: 24,
          paint: {
            "heatmap-radius": 10,
            "heatmap-opacity": 0.7,
            "heatmap-intensity": 1,
          },
        },
        "waterway-label"
      );
      EventBus.$emit("stop_heat_loading");
    },
  },
};
</script>

<style></style>
