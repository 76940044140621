<template>
  <div>
    <b-row style="margin: 0;">
      <b-col md="2" style="padding: 0px; background: #ffffff; heig">
        <b-row style="margin-left: 0.5rem; margin-top: 1rem; margin-right: 0">
          <div>
            <b-button to="/" variant="flat-dark">
              <feather-icon size="22" icon="ArrowLeftIcon" color />
            </b-button>
          </div>
          <div v-for="(item, index) in menu_buttons" :key="index">
            <b-button @click="changeMode(index)" :variant="item.color">
              <feather-icon size="22" :icon="item.icon" />
            </b-button>
          </div>
        </b-row>
        <div
          style="
            margin-left: 1rem;
            margin-right: 1rem;
            font-family: 'Montserrat', sans-serif;
            mso-line-height-rule: exactly;
            height: 1px;
            background-color: #eceff1;
            line-height: 1px;
          "
        >
          &zwnj;
        </div>
        <div style="margin-top: 1.5rem; margin-left: 0.5rem">
          <div v-if="actual_button == 0">
            <div
              class="demo-inline-spacing"
              style="margin-left: 1rem; margin-bottom: 0.5rem"
            >
              <h3 style="margin-top: 0rem">Dispositivos</h3>
            </div>
            <b-overlay
              opacity="0.5"
              :show="devices_overlay"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <div
                v-for="(item, index) in possible_devices"
                :key="index + time_cont"
              >
                <a
                  class="demo-inline-spacing devices"
                  style="padding: 0.5rem"
                  @click="changeDevice(index)"
                  v-if="device_status[index].valueTime != 1"
                >
                  <b-avatar
                    :variant="device_status[index].color"
                    style="margin-right: 0.5rem; margin-top: 0rem"
                    :key="'avata_cont:' + cont"
                  >
                    <feather-icon
                      :icon="possible_icons[item.icon].icon"
                      size="20"
                    />
                  </b-avatar>
                  <div style="margin-top: 0rem" :key="'date_' + time_cont">
                    <h5 class="mb-0">
                      {{ item.name }}
                    </h5>
                    <small
                      class="text-muted"
                      v-if="device_status[index].valueTime != 1"
                    >
                      {{
                        new Date(
                          device_status[index].valueTime * 1000
                        ).toLocaleString()
                      }}
                    </small>
                    <small class="text-muted" v-else> Não há dados </small>
                  </div>
                </a>
                <div
                  style="margin-left: 1rem; margin-right: 1rem; margin-top: 1rem font-family: 'Montserrat', sans-serif; mso-line-height-rule: exactly; height: 1px; background-color: #eceff1; line-height: 1px;"
                >
                  &zwnj;
                </div>
              </div>
            </b-overlay>
          </div>

          <div v-if="actual_button == 1">
            <div
              class="demo-inline-spacing"
              style="margin-left: 1rem; margin-bottom: 0.5rem"
            >
              <h3 style="margin-top: 0rem">Posições</h3>
            </div>
            <a
              class="demo-inline-spacing devices"
              style="padding: 0.5rem"
              v-for="(item, index) in device_datas"
              :key="index"
              @click="recordPositions(item.id)"
            >
              <b-avatar
                :variant="item.color"
                style="margin-right: 0.5rem; margin-top: 0rem"
              >
                <feather-icon icon="NavigationIcon" size="20" />
              </b-avatar>
              <div style="margin-top: 0rem">
                <h6 class="mb-0">
                  {{ item.value }}
                </h6>
                <small class="text-muted">
                  {{ item.valueTime }}
                </small>
              </div>
            </a>
          </div>

          <b-overlay
            v-if="actual_button == 2"
            opacity="0.5"
            :show="alets_overlay"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div
              v-if="actual_button == 2 && !alets_overlay"
              :key="'update_events_' + update_events"
            >
              <div
                class="demo-inline-spacing"
                style="margin-left: 1rem; margin-bottom: 0.5rem"
              >
                <h3 style="margin-top: 0rem">Eventos</h3>
              </div>
              <div class="scrollItem">
                <perfect-scrollbar>
                  <div
                    class="demo-inline-spacing devices"
                    style="padding: 0.5rem"
                    v-for="(item, index) in possible_events"
                    :key="index"
                  >
                    <b-avatar
                      variant="light-danger"
                      style="margin-right: 0.5rem; margin-top: 0rem"
                    >
                      <feather-icon
                        :icon="
                          possible_icons[
                            possible_devices.find((a) => a.id == item.deviceId)
                              .icon
                          ].icon
                        "
                        size="20"
                      />
                    </b-avatar>
                    <div style="margin-top: 0rem">
                      <h6 class="mb-0">
                        {{
                          possible_conditions.find(
                            (a) => a.id == item.condicoId
                          ).message
                        }}
                      </h6>
                      <small class="text-muted">
                        {{
                          new Date(Date.parse(item.createdAt)).toLocaleString()
                        }}
                      </small>
                    </div>
                    <div
                      style="margin-left: 1rem; margin-right: 1rem; margin-top: 1rem font-family: 'Montserrat', sans-serif; mso-line-height-rule: exactly; height: 1px; background-color: #eceff1; line-height: 1px;"
                    >
                      &zwnj;
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <b-col
        md="10"
        style="
          padding-left: 0rem; padding-right: 0rem;
          background-color: rgba(255, 255, 255, 0) none repeat scroll 0% 0%;
        "
      >
        <div
          style="
            margin-top: 1rem;
            margin-left: 1.5rem;
            z-index: 10;
            position: absolute;
          "
        >
          <b-avatar variant="light" size="40">
            <b-button
              variant="flat-dark"
              v-b-modal.heat_map_modal
              :disabled="heat_loading"
            >
              <feather-icon size="22" icon="ThermometerIcon" />
            </b-button>
          </b-avatar>

          <b-avatar variant="light" size="40" style="margin-left: 1.5rem">
            <b-button
              :disabled="fences_loading"
              @click="showFences"
              :variant="fence_button.variant"
              v-b-tooltip.hover.v-dark
              :title="fence_button.title"
            >
              <feather-icon size="22" icon="TriangleIcon" />
            </b-button>
          </b-avatar>
        </div>

        <b-col
          style="
            width: 100vw;
            height: 100vh;
            padding: 0px;
            position: absolute;
            top: 0px;
          "
        >
          <mapbox v-if="ready" :request="request_devices"> </mapbox>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      id="heat_map_modal"
      ok-only
      ok-title="Confirmar"
      hide-footer
      title-html="<p style='margin-bottom: 0rem;'>Mapa de calor</p>"
    >
      <label for="select_device">Dispositivo</label>
      <v-select
        style="margin-bottom: 1rem"
        id="select_device"
        v-model="heat_map_device"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="possible_devices"
      />
      <label for="first_date">Data de início</label>
      <flat-pickr
        style="margin-bottom: 1rem"
        id="first_date"
        v-model="first_date"
        class="form-control"
        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
      />

      <label for="last_date">Data final</label>
      <flat-pickr
        style="margin-bottom: 1rem"
        id="last_date"
        v-model="last_date"
        class="form-control"
        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
      />

      <b-card-text>
        Estado:
        <a @click="heat_map_status = !heat_map_status">
          <b-badge variant="success" v-if="heat_map_status"> Ligado </b-badge>
          <b-badge variant="danger" v-else> Desativado </b-badge>
        </a>
      </b-card-text>
      <hr />
      <div class="footer" style="text-align: end">
        <b-button variant="success" @click="confirmHeatMap()">
          Confirmar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BFormGroup,
  BCard,
  BTable,
  BOverlay,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BPagination,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
  VBTooltip,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Cookies from "js-cookie";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Mapbox from "../layouts/components/MapOnlyMap.vue";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import EventBus from "@/store/eventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import api from "../service/api.js";
import { indexOf } from 'postcss-rtl/lib/affected-props';
import eventBus from '@/store/eventBus';

export default {
  components: {
    BFormGroup,
    flatPickr,
    BOverlay,
    BFormCheckbox,
    vSelect,
    BModal,
    PerfectScrollbar,
    ToastificationContent,
    LayoutVertical,
    AppCustomizer,
    Mapbox,
    VBTooltip,
    BBadge,
    BButton,
    BPagination,
    BTable,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  async created() {
    var self = this;

    // Verificar se possui token
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }
    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }

    EventBus.$on('alert', function (variant, title, text) {
      console.log("Entrou aqui!")
      let icon = "BellIcon";
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    })

    // Atualizar o último dado do dispositivo
    EventBus.$on("device_datas", function (coords) {
      self.device_datas = [];
      coords.forEach((item, index) => {
        self.device_datas.push({
          id: index,
          value: item.value,
          valueTime: new Date((item.valueTime * 1000)).toLocaleString(),
          timeStamp: item.valueTime,
          color: 'light-dark'
        })
      });
    });

    // Atualizar horário do último dado do dispositivo
    EventBus.$on("patch_valueTime", function (data) {
      if (self.device_status.length > 0) {
        self.device_status.find(
          (find_data) => find_data.id == data.deviceId
        ).valueTime = data.valueTime;
        self.time_cont += 1;
      }
    });

    // Quando os dispositivos estiverem carregados, desligar overlay
    EventBus.$on("close_devices_overlay", function () {
      self.devices_overlay = false;
    });

    // Quando gerado ou excluído o mapa de calor, desligar overlay
    EventBus.$on("stop_heat_loading", function () {
      self.heat_loading = false;
    });

    EventBus.$on("stop_fences_loading", function() {
      self.fences_loading = false;
    })

    // Esconder navbar
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });

    // Requisições iniciais
    this.initialRequests();
  },

  destroyed() {
    // Interromper SetInverval
    this.destroy_it = true;

    // Mostrar navbar
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "floating" });
  },

  data() {
    return {
      position_list: [],
      fence_points: [],
      fence_button: {
        variant: "flat-dark",
        title: "Exibir cercas",
      },
      show_fences: false,
      fences_loading: false,
      heat_loading: false,
      first_date: "",
      last_date: "",
      heat_map_status: false,
      heat_map_device: "",
      alets_overlay: true,
      devices_overlay: true,
      update_events: 0,
      destroy_it: false,
      refresh_data: [],
      possible_events: [],
      time_cont: 0,
      device_datas: [],
      ready: false,
      cont: 0,
      request_devices: "",
      device_status: [],
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
        },
      ],
      last_device: null,
      actual_device: null,
      actual_button: 0,
      possible_colors: [
        {
          color: "light-dark",
        },
        {
          color: "light-success",
        },
      ],
      menu_buttons: [
        {
          icon: "BoxIcon",
          color: "flat-success",
        },
        {
          icon: "NavigationIcon",
          color: "flat-dark",
        },
        // {
        //   icon: "BellIcon",
        //   color: "flat-dark",
        // }, ADICIONAR DEPOIS
      ],
      devices_requests: "",
      get_variables: [],
      possible_devices: [],
      possible_conditions: [],
    };
  },
  methods: {
    recordPositions(clicked_data_id){
      var self = this;
      if( self.device_datas[clicked_data_id].color == 'light-success'){
        self.device_datas[clicked_data_id].color = 'light-dark';
      } else {
        self.device_datas[clicked_data_id].color = 'light-success';
      }
      self.position_list = self.device_datas.filter(e => e.color == 'light-success');
      eventBus.$emit('updateLastPositions', self.position_list);
    },
    async showFences() {
      let self = this;
      self.show_fences = !self.show_fences;
      self.fences_loading = true;
      if (!self.show_fences) {
        EventBus.$emit('delete_fences');
        self.fence_button = {
          variant: "flat-dark",
          title: "Exibir cercas",
        };
      } else {
        self.fence_points = [];
        let conditions = await api.get_conditions();
        let fence_conditions = conditions.filter(
          (filter_data) =>
            filter_data.rules.split("sinal: ", 2)[1].charAt(0) == 6 ||
            filter_data.rules.split("sinal: ", 2)[1].charAt(0) == 7
        );
        fence_conditions.forEach((data, index) => {
          
          // Formatando as coordenadas
          let a = String(
            data.rules.split("grid: [[", 2)[1]
          ).slice(0, -3);
          let b = a.split("],[", 5000);   
          let c = []       ;
          b.forEach((data, index) => {
            c[index] = "";
            c[index] = [data.split(",", 3)[0], data.split(",", 3)[1]];
          });
          self.fence_points[index] = c;
        });
        EventBus.$emit('create_fences', self.fence_points);
        self.fence_button = {
          variant: "flat-success",
          title: "Ocultar cercas",
        };
      }
    },
    // Gerar ou excluri mapa de calor
    confirmHeatMap() {
      // Verificar se o estado é ligado:
      if (this.heat_map_status) {
        if(this.first_date != '' && this.last_date != ''){
          this.heat_loading = true;
        EventBus.$emit(
          "heat_map",
          this.heat_map_device,
          this.first_date,
          this.last_date
        );
        } else {
          this.showToast(
            "danger",
            "Erro no intervalo",
            "BellIcon",
            "Você deve especificar a data de início e fim!"
          );
        }
      } else {
        EventBus.$emit("delete_heat_map");
      }
      // this.hideModal("heat_map_modal");
    },
    // Esconde o modal
    hideModal() {
      this.$refs[value].hide();
    },
    // Exibir notificações
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    // Requisições iniciis
    async initialRequests() {
      let self = this;

      // Get Dispositivos
      let devices = await api.get_devices();
      devices.forEach((element, index) => {
        self.device_status[index] = {
          id: element.id,
          color: "light-dark",
          valueTime: "1",
        };
        self.request_devices += "&id=" + element.id;
      });
      self.possible_devices = devices;
      self.ready = true;

      // Get Condições
      self.possible_conditions = await api.get_conditions();

      // Get Eventos
      self.refresh_data = setInterval(self.RealTimeAlert, 10000);
    },
    // Alternar entre botões do menu lateral
    changeMode(value) {
      this.menu_buttons[this.actual_button].color = "flat-dark";
      this.menu_buttons[value].color = "flat-success";
      this.actual_button = value;
    },
    // Alternar entre dispositivos
    changeDevice(value) {
      // Colorir avatar do dispositivo
      this.actual_device = this.device_status[value].id;
      if (this.last_device != null) {
        this.device_status[this.last_device].color = "light-dark";
        this.device_status[value].color = "light-success";
        this.last_device = value;
      } else {
        this.device_status[value].color = "light-success";
        this.last_device = value;
      }
      
      // Resetar valores das ultimas posições
      self.position_list = [];

      // Chamar requisição em MapBox: Voar até dispositivo
      EventBus.$emit("change_device", this.actual_device);
      this.cont += 1;
    },
    // Função para pegar as posições em tempo real
    async RealTimeAlert() {
      let self = this;
      if (self.destroy_it) {
        clearInterval(self.refresh_data);
      }

      let alerts = await api.get_events();
      if (alerts != "") {
        let last_events = self.possible_events;
        self.possible_events = alerts.filter(
          (filter_data) => filter_data.visto == 0
        );
        if (
          last_events.length != self.possible_events.length &&
          self.alets_overlay == false
        ) {
          self.showToast(
            "warning",
            "Novo evento",
            "BellIcon",
            "Um novo evento foi criado!"
          );
        }
      }

      self.alets_overlay = false;
      self.update_events += 1;
    },
  },
};
</script>
.
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.devices:hover {
  background: #f9f9f9;
}

.scrollItem {
  position: relative;
  height: 80vh;
}

.ps {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

// Cor do círculo de overlay
.text-primary {
  color: #ff4804 !important;
}

[dir] .btn-primary {
  background-color: #ff4804 !important;
  border-color: #c94d20 !important;
}
</style>